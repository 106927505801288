#file[type="file"] {
  display: none;
}

.label-holder {
  width: 100%;
  height: 50px;
  margin-top: 4em;
  display: grid;
  place-items: center;
}

.label {
  height: 100px;
  width: 200px;
  border: 2px dotted #377aa0;
  border-radius: 20px;
  color: #377aa0;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.01071em;
}

.result {
  min-height: 100%;
  max-height: auto;
  width: 100%;
  /* margin-top: 1rem; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  width: 160px;
  height: 140px;
  object-fit: cover;
  padding: 0.5rem;
  border-radius: 18px;
}

.img-wrap {
  position: relative;
  cursor: pointer;
  font-size: 27px;
  color: rgba(37, 26, 26, 0.959);
}

.img-wrap .close {
  position: absolute;
  top: 0.01rem;
  right: 0.01rem;
  z-index: 100;
}

.image-wrapperr {
  width: 130px;
  height: 100px;
  object-fit: cover;
  padding: 0.5rem;
  border-radius: 20px;
}

.img-wrapp {
  position: relative;
  cursor: pointer;
  font-size: 27px;
  color: rgba(58, 54, 54, 0.959);
}

.img-wrapp .closee {
  position: absolute;
  top: 0.01rem;
  right: 0.01rem;
  /* z-index: 100; */
}
